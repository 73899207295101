// Navbar
.navbar {
	z-index: 99999;
	position: relative;
	.navbar-brand {
		position: relative;
		text-indent: -9999rem;
		width: 120px;
		&:before {
			position: absolute;
			top: 11px;
			left: 0;
			display: block;
			width: 120px;
			height: 72px;
			content: '';
			background: url('../images/logo.png') no-repeat;
			background-position: 0;
			background-size: 120px 72px;
			z-index: 999;
		}
	}
}

// Footer
.footer {
	padding: ($spacer) 0;
	color: $white;
	background-color: $black;
	li + li {
		margin-left: $spacer;
	}

	li {
		margin-right: 10px;
	}

	.svg-inline--fa.fa-w-14 {
    width: 0.875em;
    font-size: 36px;
}
}


// Page
.page {
	margin: ($spacer * 6) 0;
	.btn-primary {
      margin-top: 3rem;
      margin-bottom: -6rem;
     }
     p {
     	line-height: 2.5rem;
       }
     &.page-about {
     	margin-bottom: 9rem;
     }
 }

.dienstenintro {
     	width: auto;
        text-align: left;
        margin-top: 2rem;
     }

.dienstenintro1 {
    width: auto;
    text-align: left;
    margin-top: 2.4rem;
    line-height: 2.5rem;
    margin-top: 32px;
}

.dienstentitel {
     	text-align: left;
     	min-height: 50px;
     	font-size: 45px;
     }

.dienstentitel1 {
     	text-align: left;
     	margin-top:	4.6rem;
     	line-height: 2.5rem;
        }


h4, .h4 {
    font-size: 1.5rem;
    margin-top: 2rem;
}

.page .btn-primary {
    margin-top: 1rem;
    margin-bottom: 0rem;
}

h2.rechtertitel {
     	text-align: left;
     	min-height: 50px;
     	margin-top:	1.1rem;
     }

.kolomrechtspadding {
		margin-left: 50px;
		margin-top: -29px;
	}

.dienstentekst {
	margin-bottom: 50px;
	margin-top: -20px;
}


// Hero
.hero {
	height: 300px;
	position: relative;
		&__image {
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
		}
		.container {
			z-index: 99;
			position: relative;
		}
		h2, .h2 {
        margin-top: 6rem;
		}
}

.text {
	color: $white;
	text-shadow: 2px 2px #000000;
	font-size: 70px;
	font-weight: 600;
}

.lead {
	color: $white;
	text-shadow: 2px 2px #000000;
}

// Services
.services {
	padding: ($spacer * 4) 0;
	background-color: $white;
}

// About
.about {
	padding: ($spacer * 3) 0;
	background-color: #ececec;
}

.contact {
	padding: ($spacer * 3) 0;
	margin-left: 5rem!important;
	ul {
		line-height: $line-height-base + .8;
	}
	hr {
	    margin-bottom: 1.5rem;
	   }
}	   

.offset-lg-1 {
    margin-left: 0%;
}

.text-center {
    text-align: center !important;
}

// Has Statements
.has-ratio {
	background-size: cover!important;
	background-position: center!important;
}

// Has Mask
.has-mask {
	position: relative;
	&:before {
		content: "";
		background-color: transparentize($black,.5);
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
}

