// Above 34em (544px)
@include media-breakpoint-up(xs) {
	.text {
    font-size: 30px;
	}

	.services {
    padding: 0rem 0;
	}

	.lead {
    font-size: 0.8rem;
	}

	h1, .h1 {
    font-size: 2.3rem;
    margin-top: -30px;
	}

	.btn-primary {
    font-size: 13px;
	}

	.contact {
	margin-left: auto!important;
	text-align: center;
	}

	.footer li + li {
    margin-left: 0rem;
	}

	.dienstenintro {
    text-align: center;
	}

	.dienstentitel {
    text-align: center;
    font-size: 28px;
	}

	.text-left {
    text-align: center !important;
	}

	h2.rechtertitel {
    text-align: center;
	}

	.dienstentitel1 {
    text-align: center;
	}

	.dienstenintro1 {
    text-align: left;
    margin-left: 10px;
	}

	.text-right {
    text-align: center !important;
	}

	.footer {
    text-align: center;
	}

	.kolomrechtspadding {
		margin-left: 0px;
	}
}


// Above 48em (768px)
@include media-breakpoint-up(sm) {
.text {
    font-size: 40px;
	}

.lead {
    font-size: 1rem;
	}

h1, .h1 {
    font-size: 2.3rem;
    margin-top: -30px;
	}

.btn-primary {
    font-size: 14px;
	}

.contact {
	margin-left: auto!important;
	text-align: center;
	}

.dienstenintro {
    text-align: center;
	}

.dienstentitel {
    text-align: center;
    font-size: 35px;
	}

.text-left {
    text-align: center !important;
	}

h2.rechtertitel {
    text-align: center;
	}

.dienstentitel1 {
    text-align: center;
	}

.dienstenintro1 {
    text-align: left;
    margin-left: 5px;
	}

.text-right {
    text-align: center !important;
	}

.footer {
    text-align: left;
}

}

// Above 62em (992px)
@include media-breakpoint-up(md) {

	// Navbar
	.navbar {
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		background-color: transparent!important;
		.navbar-brand {
			width: 250px;
			&:before {
				width: 250px;
				height: 150px;
				background-position: 0;
				background-size: 250px 150px;
			}
		}
	}

	.text {
    font-size: 50px;
	}

	h1, .h1 {
    font-size: 2.5rem;
    margin-top: -5px;
	}

	.btn-primary {
    font-size: 16px;
	}

	.contact {
	margin-left: auto!important;
	text-align: left;
	}

	.dienstenintro {
    text-align: left;
	}

	.dienstentitel {
    text-align: left;
	}

	.text-left {
    text-align: left !important;
	}

	h2.rechtertitel {
    text-align: left;
	}

.dienstentitel1 {
    text-align: left;
	}

.dienstenintro1 {
    text-align: left;
    margin-left: 0px;
	}
.footer {
    text-align: left;
	}

}

// Above 62em (992px) 
@include media-breakpoint-up(lg) {
	// Hero
	.hero {
		height: 500px;
		&.hero--large {
			height: 100vh;
			}
	}

	.lead {
    font-size: 1.25rem;
	}

	.text {
    font-size: 70px;
	}

	// Services
	.services {
		padding: ($spacer * 5) 0;
	}

	// About
	.about {
		padding: ($spacer * 5) 0;
	}

	h1, .h1 {
    font-size: 3rem;
    margin-top: 0px;
	}

	.contact {
	margin-left: auto!important;
	text-align: left;
	}

	.dienstenintro {
    text-align: left;
	}

	.dienstentitel {
    text-align: left;
	}

	.text-left {
    text-align: left !important;
	}

	h2.rechtertitel {
    text-align: left;
	}

	.dienstentitel1 {
    text-align: left;
	}

	.dienstenintro1 {
    text-align: left;
    margin-left: 0px;
	}

	.text-right {
    text-align: right !important;
	}

	.footer {
    text-align: left;
	}

	.kolomrechtspadding {
		margin-left: 50px;
		margin-top: -29px;
	}

	.kolomrechtspadding2 {
		margin-left: 50px;
		margin-top: -14px;
	}

	.rechtertekst {
		text-align: left;
		margin-top: 26px;
	}

}

// Above 75em (1200px)
@include media-breakpoint-up(xl) {
	.lead {
    font-size: 1.5rem;
	}

	.text {
    font-size: 70px;
	}

	.contact {
	margin-left: auto!important;
	text-align: left;
	}

	.dienstenintro {
    text-align: left;
	}

	.dienstentitel {
    text-align: left;
	}

	.text-left {
    text-align: left !important;
	}

	h2.rechtertitel {
    text-align: left;
	}

	.dienstentitel1 {
    text-align: left;
	}

	.dienstenintro1 {
    text-align: left;
    margin-left: 0px;
	}

	.text-right {
    text-align: right !important;
	}

	.footer {
    text-align: left;
	}
}